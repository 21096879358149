<template>
  <v-container>
    <div class="trainees-container card-shadow">
      <div class="search-container">
        <v-text-field
          solo
          flat
          class
          label="search users"
          v-model="search"
          v-on:input="searchTrainees(search)"
        ></v-text-field>
      </div>
      <div class="grey lighten-4 d-flex pa-0 ma-0 align-center">
        <div class="filter">
          <div class="d-flex align-center mt-2">
            <p class="pr-8 pl-3 grey--text">FILTER</p>
          </div>
          <div class="select-container d-flex align-center pa-0 ma-0 justify-center">
            <v-select
              v-model="selected"
              flat
              class="float-right"
              dense
              :items="items"
              placeholder="All"
              @change="filterTrainees(allTrainees)"
            ></v-select>
          </div>
        </div>
      </div>
      <v-divider class="mb-1"></v-divider>
      <div class="list-container" v-if="trainees.length > 0">
        <div v-for="(trainee, index) in trainees" :key="index">
          <trainee-card :trainee="trainee" />
        </div>
        <trigger @triggerIntersected="loadMore" />
      </div>
    </div>
  </v-container>
</template>

<script>
import TraineeCard from "../components/TraineeCard.vue";
import Trigger from "../components/Trigger.vue";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "lodash.debounce";

export default {
  components: {
    TraineeCard,
    Trigger
  },
  async created() {
    this.storeCourseId({
      courseId: this.courseId
    });
    const trainees = await this.getSubmissionFiles({
      courseId: this.courseId,
      limit: this.limit,
      skip: this.skip
    });
    for (var i in trainees) {
      this.allTrainees.push({
        submissionId: trainees[i]._id,
        name: `${trainees[i].user.firstName} ${trainees[i].user.lastName}`,
        email: trainees[i].user.email,
        evaluated: trainees[i].isEvaluated,
        id: trainees[i].user._id,
        courseId: this.courseId,
        submissions: trainees[i].submissions,
        image: trainees[i].Profile.url
      });
    }
    this.storeTrainees(trainees);
    this.fetchCriteria();
  },
  props: ["courseId"],
  methods: {
    ...mapActions("evaluator", {
      getSubmissionFiles: "getSubmissionFiles",
      getCriteria: "getCriteria",
      storeCourseId: "storeCourseId",
      searchTrainee: "searchTrainee"
    }),

    async fetchCriteria() {
      await this.getCriteria();
    },
    storeTrainees(trainees) {
      for (var i in trainees) {
        this.trainees.push({
          submissionId: trainees[i]._id,
          name: `${trainees[i].user.firstName} ${trainees[i].user.lastName}`,
          email: trainees[i].user.email,
          evaluated: trainees[i].isEvaluated,
          id: trainees[i].user._id,
          courseId: this.courseId,
          submissions: trainees[i].submissions,
          image: trainees[i].Profile.url
        });
      }
    },
    async loadMore() {
      this.skip = this.skip + 30;
      const trainees = await this.getSubmissionFiles({
        courseId: this.courseId,
        limit: this.limit,
        skip: this.skip
      });
      this.storeTrainees(trainees);
    },
    searchTrainees: debounce(async function(name) {
      this.trainees = [];

      if (name == "") {
        this.trainees = this.allTrainees;
        return;
      }
      const trainees = await this.searchTrainee({
        name: name
      });
      this.storeTrainees(trainees);
    }, 500),

    filterTrainees(trainees) {
      // console.log(this.selected);
      let filteredTrainees;
      switch (this.selected) {
        case "All":
          filteredTrainees = trainees.filter(function(trainee) {
            return true;
          });
          break;
        case "Rated":
          filteredTrainees = trainees.filter(function(trainee) {
            return trainee.evaluated == true;
          });
          break;
        case "Not Rated":
          filteredTrainees = trainees.filter(function(trainee) {
            return trainee.evaluated == false;
          });
          break;
      }
      this.trainees = filteredTrainees;
    }
  },
  data: () => ({
    items: ["All", "Rated", "Not Rated"],
    trainees: [],
    limit: 30,
    skip: 0,
    search: "",
    selected: "",
    allTrainees: []
  })
};
</script>

<style scoped>
.trainees-container {
  box-shadow: 0px 0px 25px #00000017;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  height: 611px;
  width: 796px;
}
.search-container {
  height: 52px;
}

.filter {
  display: flex;
  width: 500px;
  height: 50px;
}
.select-container {
  width: 130px;
}

.list-container {
  overflow-y: auto;
  height: 100%;
}
</style>
