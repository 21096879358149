<template>
  <div class="card-container" v-on="$listeners" v-bind="$attrs">
    <div class="image-container d-flex justify-center d-flex align-center">
      <v-img
        v-if="trainee.image != null"
        max-height="52"
        max-width="52"
        :src="trainee.image"
      ></v-img>
      <div v-else class="image d-flex justify-center align-center primaryLight">
        <p class="white--text">{{ initials }}</p>
      </div>
    </div>
    <div
      @click="routeToTraineeInfo(trainee)"
      class="divCursor content-container d-flex align-center"
    >
      <div class="d-flex flex-column mt-1">
        <p>{{ trainee.name }}</p>
        <p class="grey--text body-2 mt-1">{{ trainee.email }}</p>
      </div>
    </div>
    <div class="options-container d-flex align-center mr-5">
      <v-btn
        small=""
        v-if="trainee.evaluated"
        @click="routeToEvaluation(trainee)"
        class="evaluation primary white--text mt-1"
        >Re Evaluate</v-btn
      >
      <v-btn
        small=""
        @click="routeToEvaluation(trainee)"
        v-else
        class="evaluation textOrange white--text mt-1"
        >Evaluate</v-btn
      >
      <!-- <div v-else class="divCursor evaluation textOrange white--text mt-1 mr-2">Evaluation</div> -->
      <div class="divCursor mb-0">
        <v-icon
          @click="routeToTraineeInfo(trainee)"
          size="30"
          color="primary"
          class="arrow"
          >mdi-apple-keyboard-control</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trainee-card",
  props: ["trainee"],
  data: () => ({
    checkbox: true,
    headertitle: "Course Name",
  }),

  methods: {
    routeToTraineeInfo(trainee) {
      this.$router.push({
        name: "evaluator-trainee-info",
        params: {
          submissionId: trainee.submissionId,
          courseId: trainee.courseId,
          name: trainee.name,
          email: trainee.email,
          submissions: trainee.submissions,
          image: trainee.image,
          evaluated: trainee.evaluated,
        },
      });
      // this.$router.push({ name: "evaluator-trainee-info" });
    },
    routeToEvaluation(trainee) {
      this.$router.push({
        name: "evaluate-trainee",
        query: {
          i: trainee.submissionId,
        },
      });
    },
  },
  computed: {
    initials: function() {
      let name = this.trainee.name;
      let initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();

      return initials;
    },
  },
};
</script>

<style scoped>
p {
  padding: 0px;
  margin: 0px;
  line-height: 16px;
}
.card-container {
  cursor: pointer;
  height: 55px;
  border-bottom: 0.04px solid rgb(207, 205, 205);
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  align-content: center;
}

.image {
  width: 38px;
  height: 38px;
  border: 2px solid blue;
  border-radius: 6px;
}
.divCursor {
  cursor: pointer;
}

.evaluation {
  border: 2px solid red;
  margin-right: 25px;
  padding: 1px 8px;
  border-radius: 4px;
}
.arrow {
  transform: rotate(90deg);
}
</style>
