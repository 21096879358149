<template>
  <div>
    <admin-header :title="headerTitle" :links="bcLinks"></admin-header>
    <label class="caption textOrange--text font-weight-bold">Trainees</label>
    <trainees class="mt-3" :courseId="courseId" />
  </div>
</template>

<script>
import AdminHeader from "@/beez-admin/components/AdminHeader";
import Trainees from "./Trainnees";

export default {
  name: "TraineesList",
  data() {
    return {
      headerTitle: "",
      courseId: ""
    };
  },
  components: {
    AdminHeader,
    Trainees
  },
  created() {
    this.headerTitle = this.$route.query.c;
    this.courseId = this.$route.query.ci;
    this.training = this.$route.query.t;
    this.trainingId = this.$route.query.ti;
  },
  computed: {
    bcLinks() {
      return [
        {
          text: "Elevation Module",
          disabled: true,
          to: {
            name: "elevation-module"
          }
        },
        {
          text: "Training",
          to: {
            name: "evaluator-training"
          },
          disabled: false
        },
        {
          text: this.training,
          to: {
            name: "evaluator-course-home",
            query: {
              course: this.training,
              id: this.trainingId
            }
          },
          disabled: false
        },
        {
          text: this.headerTitle,
          disabled: true
        },
        {
          text: "Trainees",
          disabled: true
        }
      ];
    }
  }
};
</script>

<style>
</style>